<template>
  <section class="page-fund">
    <div class="banner">
      <p class="title">JWP FUND</p>
      <p class="sub-title">Building A Better World With Us</p>
    </div>
    <ul class="funds">
      <li>
        <a href="/fund/portugal">
          <span>Portugal</span>
        </a>
      </li>
      <li>
        <a href="/fund/cyprus">
          <span>Cyprus</span>
        </a>
      </li>
      <li>
        <a href="/fund/turkiye">
          <span>Türkiye</span>
        </a>
      </li>
      <li>
        <a href="/fund/united_states">
          <span>America</span>
        </a>
      </li>
      <li>
        <a href="/fund/greece">
          <span>Greece</span>
        </a>
      </li>
    </ul>
    <div class="operations">
      <p class="title">
        <span class="van-hairline--bottom">Fund operation procedure</span>
      </p>
      <div class="steps">
        <div class="item">
          <div class="left"></div>
          <div class="center">
            <img
              style="height: 21px"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/bianti.png"
            />
          </div>
        </div>

        <div class="item">
          <div class="left">
            <div class="line1"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 01</div>
          </div>
          <div class="right">
            <div class="name"><i></i> FUNDRAISING</div>
            <div class="content">
              Investors' subscriptions lmmigration applications
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
          </div>
          <div class="center">
            <div class="img">
              <img
                style="height: 21px"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/biaoti.png"
              />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 02</div>
          </div>
          <div class="right">
            <div class="name"><i></i> IC MEETING</div>
            <div class="content">Fund manager Deal selection</div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 03</div>
          </div>
          <div class="right">
            <div class="name"><i></i> DUE DILIGENCE</div>
            <div class="content">
              Legal DD、Financial DD Valuation DD、PropertyDD
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 04</div>
          </div>
          <div class="right">
            <div class="name"><i></i> BOARD MEETING</div>
            <div class="content">The Board Voting</div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 05</div>
          </div>
          <div class="right">
            <div class="name"><i></i> DEAL CLOSE</div>
            <div class="content">
              SPA signing Depository bank's verification and execution
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
          </div>
          <div class="center">
            <div class="img">
              <img
                style="height: 21px"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/post.png"
              />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 06</div>
          </div>
          <div class="right">
            <div class="name"><i></i> POST-ACQUISITION MANAGEMENT</div>
            <div class="content">
              Professional operator Fund manager supervision
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line"></div>
          </div>
          <div class="center">
            <div class="img">
              <img
                style="height: 21px"
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/exit.png"
              />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <div class="line2"></div>
            <div class="ball"></div>
          </div>
          <div class="center">
            <div class="num"><i></i>Step 07</div>
          </div>
          <div class="right">
            <div class="name"><i></i> EXlT</div>
            <div class="content">
              Institutional investor buyout Retail Refinance through RElTs or
              debt instruments
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="advantages">
      <p class="title">
        <span class="van-hairline--bottom">Five Advantages Of JWP Funds</span>
      </p>
      <div class="scroll-wrap">
        <div class="boxs">
          <div class="box">
            <div class="inner">
              <van-image
                height="44px"
                width="44px"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/tubiao1.png"
              />
              <p class="kw">Compliant & Prudent</p>
              <p class="desc">
                <span
                  >Immigration program compatible Government regulation Risk
                  management</span
                >
              </p>
            </div>
          </div>
          <div class="box">
            <div class="inner">
              <van-image
                height="44px"
                width="44px"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/tubiao2.png"
              />
              <p class="kw">ProfessionalService Provider</p>
              <p class="desc">
                <span
                  >EU fund manager Local depository bank Experienced
                  administrator Independent auditor Top-tier legal advisor</span
                >
              </p>
            </div>
          </div>
          <div class="box">
            <div class="inner">
              <van-image
                height="44px"
                width="44px"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/tubiao3.png"
              />
              <p class="kw">Conservative Investing</p>
              <p class="desc">
                <span
                  >Real estate focused Sector specialization Value-add strategy
                  No leverage</span
                >
              </p>
            </div>
          </div>
          <div class="box">
            <div class="inner">
              <van-image
                height="44px"
                width="44px"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/tubiao4.png"
              />
              <p class="kw">Defined ExitStrategy</p>
              <p class="desc">
                <span
                  >Diversified asset allocation Strong liquidity Coherent
                  divestment plan Clear disposal schedule</span
                >
              </p>
            </div>
          </div>
          <div class="box">
            <div class="inner">
              <van-image
                height="44px"
                width="44px"
                lazy-load
                src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/tubiao5.png"
              />
              <p class="kw">Transparency</p>
              <p class="desc">
                <span
                  >Hassle-free investment Efficient decision making No hidden
                  charge No maintenance expenditure</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <van-image
        lazy-load
        src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/map_en.png"
      />
    </div>
  </section>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Fund Overview";
  },
};
</script>

<style lang="less" scoped>
.page-fund {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 158px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/bg.png")
      no-repeat center center / cover;
    .title {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .sub-title {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .funds {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding: 24px 22px 0px;
    li {
      &:first-child {
        a {
          background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/putaoya.png?v1")
            no-repeat center center / cover;
        }
      }
      &:nth-child(2) {
        a {
          background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/saipulusi.png?v1")
            no-repeat center center / cover;
        }
      }
      &:nth-child(3) {
        a {
          background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/turkiye.png?v1")
            no-repeat center center / cover;
        }
      }

      &:nth-child(4) {
        a {
          background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/meiguo.png?v1")
            no-repeat center center / cover;
        }
      }
      &:last-child {
        a {
          background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/xila.png?v1")
            no-repeat center center / cover;
        }
      }
      a {
        position: relative;
        display: inline-block;
        width: 98px;
        height: 104px;
        border-radius: 4px;
        margin: 0 5px 16px;
        &:active {
          opacity: 0.9;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          padding: 4px 6px;
          font-size: 10px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          background: #0052d7;
          border-top-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  .operations {
    padding: 16px 0 10px;
    .title {
      padding-bottom: 25px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }

    .steps {
      background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/fund/ditu.png")
        no-repeat center center / cover;
      .item {
        display: flex;
        .left {
          margin-left: 20px;
          width: 24px;
          display: flex;
          justify-content: center;
          .ball {
            margin-top: 4px;
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #0052d7;
            border: 3px solid #d0dffe;
          }
          .line2 {
            border-left: #c1c4ce 1px solid;
            height: 10px;
          }
          .line1 {
            border-left: #c1c4ce 1px solid;
            height: 100%;
            margin-top: 5px;
          }
          .line {
            border-left: #c1c4ce 1px solid;
            height: 100%;
          }
        }
        .center {
          // width: 100px;

          margin-left: 10px;
          .img {
            // padding: 5px 10px;
          }
          .num {
            background: #0052d7;
            color: #fff;
            padding: 5px 10px;
            font-size: 14px;
            border-radius: 2px;
            white-space: nowrap;
            i {
              position: absolute;
              margin-top: 2px;
              margin-left: -14px;
              // background: #0052D7;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px 6px 6px 0;
              border-color: transparent #0052d7 transparent #0052d7;
            }
          }
        }
        .right {
          margin-left: 10px;
          flex: 1;
          .name {
            margin-top: 2px;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: #07234b;
            line-height: 20px;
            i {
              width: 8px;
              height: 8px;
              background: #0052d7;
              border-radius: 50%;
              margin-right: 6px;
            }
          }
          .content {
            font-weight: 400;
            font-size: 12px;
            color: #07234b;
            line-height: 17px;
            margin: 0 40px 25px 14px;
          }
        }
      }
    }
  }
  .advantages {
    padding-bottom: 62px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .scroll-wrap {
      width: 100vw; 
      overflow-x: scroll;
      .boxs {
        display: flex;
        padding-left: 22px;
        .box {
          flex-shrink: 0;
        
          overflow-y: hidden;
          background: #e4edfc;
          margin-right: 10px;
          border-radius: 4px;       

          &:last-child {
            padding-right: 22px;
          }
          .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 173px;
            // height: 206px;
            padding: 20px 10px;
            
        
            img {
              height: 50px;
            }
            .kw {
              height: 20px;
              margin-top: 10px;
              font-weight: 600;
              font-size: 12px;
              color: #252c39;
              text-align: left;
              font-style: normal;
              // line-height: 20px;
              text-align: center;
            }
            .desc {
              display: flex;
              flex-direction: column;
              margin-top: 8px;
              font-weight: 300;
              font-size: 11px;
              color: #4e4e4e;
              text-align: center;
              span {
                display: inline-block;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }
  .map {
    padding-bottom: 50px;
    text-align: center;
    .van-image {
      width: 335px;
    }
  }
}
</style>
